// import React, { useState } from 'react';
// import { Drawer, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
// import RegistrationForm from './components/RegistrationForm';
// import Marksheet from './components/Marksheet';
// import Certificate from './components/Certificate'; // Import the Certificate component
// //import Login from './components/Login';


// const drawerWidth = 240;

// const LeftPanel = () => {
//   const [currentTab, setCurrentTab] = useState('studentRegistration');

//   const handleTabChange = (tab) => {
//     setCurrentTab(tab);
//   };

//   const renderContent = () => {
//     switch (currentTab) {
//       case 'studentRegistration':
//         return <RegistrationForm />;
//       case 'marksheet':
//         return <Marksheet />;
//       case 'certificate':
//         return <Certificate />; // Return the Certificate component
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box display="flex">
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//             backgroundColor: '#3f51b5',
//             color: '#fff',
//           },
//         }}
//         variant="permanent"
//         anchor="left"
//       >
//         <List>
//           <ListItem button onClick={() => handleTabChange('studentRegistration')}>
//             <ListItemText primary="Student Registration" />
//           </ListItem>
//           <Divider />
//           <ListItem button onClick={() => handleTabChange('marksheet')}>
//             <ListItemText primary="Marksheet" />
//           </ListItem>
//           <Divider />
//           <ListItem button onClick={() => handleTabChange('certificate')}>
//             <ListItemText primary="Certificate" />
//           </ListItem>
//         </List>
//       </Drawer>
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           p: 3,
//           backgroundColor: '#f5f5f5',
//           height: '100vh',
//         }}
//       >
//         {renderContent()}
//       </Box>
//     </Box>
//     // <Login/>
//   );
// };

// export default LeftPanel;

//Working

// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import LeftPanel from './components/LeftPanel';
// import Login from './components/Login';

// const App = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = () => {
//     setIsAuthenticated(true);
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login onLogin={handleLogin} />} />
//         <Route path="/" element={isAuthenticated ? <LeftPanel /> : <Navigate to="/login" />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;

//Working

// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import LeftPanel from './components/Frontend/LeftPanel';
// import Login from './components/Frontend/Login';

// const App = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = () => {
//     setIsAuthenticated(true); // Update authentication state
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login onLogin={handleLogin} />} />
//         <Route path="/" element={isAuthenticated ? <LeftPanel /> : <Navigate to="/login" />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;


//Testing for logout
// import React, { useState } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import LeftPanel from './components/Frontend/LeftPanel';
// import Login from './components/Frontend/Login';

// const App = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   const handleLogin = () => {
//     setIsAuthenticated(true); // Update authentication state
//   };

//   const handleLogout = () => {
//     setIsAuthenticated(false); // Update authentication state on logout
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login onLogin={handleLogin} />} />
//         <Route path="/" element={isAuthenticated ? <LeftPanel onLogout={handleLogout} /> : <Navigate to="/login" />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;



//Testing for logout refresh
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LeftPanel from './components/Frontend/LeftPanel';
import Login from './components/Frontend/Login';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check localStorage for authentication status on initial render
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Save to localStorage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove from localStorage
  };

  // Optional: Effect to synchronize state with localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(localStorage.getItem('isAuthenticated') === 'true');
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/" element={isAuthenticated ? <LeftPanel onLogout={handleLogout} /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
