import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';
import certificateTemplate from '../Img/FormatNew1.png'; // Ensure this is a valid image path
import zafchab from '../Fonts/ZAFCHAB.TTF'; 
import Bold from '../Fonts/OpenSans-Bold.ttf';

//Date Format dd-mm-yyyy
const formatDate = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');

  // Pad month and day to ensure they are always two digits
  const formattedDay = day.padStart(2, '0');
  const formattedMonth = month.padStart(2, '0');

  return `${formattedDay}-${formattedMonth}-${year}`;
};

//Course Full Form
const getCourseFullForm = (courseCode) => {
  const courseMap = {
    DFSE: 'Diploma  in  Fire  and  Safety  Engineering',
    CFSE: 'Certificate In Fire & Safety Engineering',
    DIS: 'Diploma in Industrial Safety',
    ADISE: 'Advance Diploma in Industrial Safety Engineering',
    ADFISE: 'Advance Diploma in Fire & Industrial Safety Engineering',
    DFISE: 'Diploma in Fire & Industrial Safety Engineering',
  };

  return courseMap[courseCode] || '';
};

//ISSUED DATE CODE
const extractStartMonthYear = (session) => {
  const [startSession] = session.split(' - ');
  const [startMonthStr, startYear] = startSession.split(' ');

  // Map month names to numbers
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const startMonth = monthMap[startMonthStr];
  const startYearInt = parseInt(startYear, 10);

  return { month: startMonth, year: startYearInt };
};

//Added Suffix to Date
const getFirstMonday = (month, year) => {
  let date = new Date(year, month, 1); // Start with the 1st day of the given month and year
  const dayOfWeek = date.getDay();
  const daysUntilMonday = (dayOfWeek === 0 ? 1 : 8 - dayOfWeek) % 7;
  date.setDate(date.getDate() + daysUntilMonday);
  return date;
};

const formatIssueDate = (date) => {
  const day = date.getDate();
  const monthName = date.toLocaleString('en-US', { month: 'long' });
  const year = date.getFullYear();
  const formattedDay = formatDateWithSuffix(day);
  return `${formattedDay} ${monthName} ${year}`;
};

const formatDateWithSuffix = (day) => {
  if (day > 3 && day < 21) return day + 'th'; // Special case for 11th-13th
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const index = (day % 10 > 3) ? 0 : day % 10;
  return day + suffixes[index];
};


const splitCourseName = (courseName) => {
  const words = courseName.split(' ');
  
  // Determine the number of words to use based on character count
  const totalCharCount = courseName.replace(/\s+/g, '').length; // Remove spaces to count characters
  const charThreshold = 15;
  let numberOfWords;
  
  if (totalCharCount <= charThreshold) {
    numberOfWords = 3; // Use the first 3 words if character count is less than or equal to 15
  } else {
    numberOfWords = 2; // Use the first 2 words if character count exceeds 15
  }
  
  const part1 = words.slice(0, numberOfWords).join(' ');
  const part2 = words.slice(numberOfWords).join(' ');
  
  return [part1, part2];
};

//Certificate Content Font
Font.register({
  family: 'Zafchab',
  src: zafchab,
});

//Bold Font
Font.register({
  family: 'Bold',
  src: Bold,
});


const Certificate = () => {
  const [name, setName] = useState('');
  const [enrollmentNumber, setEnrollmentNumber] = useState('');
  const [session, setSession] = useState('');
  const [courseCode, setCourseCode] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoError, setPhotoError] = useState('');
  const [certificateGenerated, setCertificateGenerated] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [fathersName, setFathersName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [srNo, setSerialNo] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [startSession, endSession] = session.split(' - ');
  const [centerCode, setcenterCode] = useState('');

  //ISSUED DATE
  const { month, year } = extractStartMonthYear(session);
  const issuedDate = getFirstMonday(month, year);
  const formattedIssuedDate = formatIssueDate(issuedDate);

  //SPLIT COURSE NAME
  //const [coursePart1, coursePart2] = splitTextByWords(getCourseFullForm(courseCode), 3);
  const courseFullForm = getCourseFullForm(courseCode);
  const [coursePart1, coursePart2] = splitCourseName(courseFullForm);


  useEffect(() => {
    // Reset certificate generation and photo state when enrollment number changes
    setCertificateGenerated(false);
    setShowDownloadButton(false);
    setPhoto(null);
  }, [enrollmentNumber]);

  const handleSearch = async () => {
    if (!enrollmentNumber) {
      setError('Enrollment Number is required.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://backend.navituschemicals.in/search1.php', {
        params: { enrollmentNumber }
      });
      setSearchResults(response.data);
      if (response.data.length > 0) {
        const { name, session, courseCode, fathersName, dateOfBirth, srNo, centerCode } = response.data[0];
        setName(name || '');
        setSession(session || '');
        setCourseCode(courseCode || '');
        setFathersName(fathersName || '');
        setDateOfBirth(dateOfBirth || '');
        setSerialNo(srNo || '');
        setcenterCode(centerCode || '');
        setSearchCompleted(true);
      } else {
        setError('No data found for the provided enrollment number.');
        setSearchCompleted(false);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('Failed to fetch search results. Please try again.');
      setSearchCompleted(false);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCertificate = () => {
    if (!photo) {
      setPhotoError('Please upload a photo first.');
      return;
    }

    setPhotoError('');
    setCertificateGenerated(true);
    setShowDownloadButton(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        setPhotoError('Please upload a JPG or PNG image.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setPhoto(base64String);
        setPhotoError('');
      };
      reader.readAsDataURL(file);
    }
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      padding: 20,
    },
    image: {
      width: 100,
      height: 100,
      borderRadius: 50,
      marginBottom: 20,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 10,
    },
    text: {
      fontSize: 18,
      marginBottom: 5,
    },
    container: {
      marginTop: 20,
      textAlign: 'center',
    },
    certificateContainer: {
      marginTop: 20,
      border: '1px solid #ddd',
      padding: 10,
      borderRadius: 4,
    },
    downloadButton: {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#1976d2',
      padding: '10px 20px',
      borderRadius: '4px',
      fontWeight: 'bold',
      display: 'inline-block',
      marginLeft: 16,
    },
  });
  

  const calculateSessionDuration = (session) => {
    const [start, end] = session.split(' - ');
    const [startMonth, startYear] = start.split(' ');
    const [endMonth, endYear] = end.split(' ');
  
    // Month mapping
    const monthMap = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
  
    const startMonthIndex = monthMap[startMonth];
    const endMonthIndex = monthMap[endMonth];
  
    const yearDifference = parseInt(endYear) - parseInt(startYear);
    const monthDifference = endMonthIndex - startMonthIndex;
  
    // Total months
    const totalMonths = yearDifference * 12 + monthDifference;
  
    // Format output based on the total months
    if (totalMonths === 0) {
      return "Less than a month";
    } else if (totalMonths === 1) {
      return "One Month";
    } else if (totalMonths === 6) {  // Highlighted change for 6 months
      return "Six Months";  // Highlighted change for 6 months
    } else if (totalMonths === 12) { // Highlighted change for 12 months
      return "One Year";   // Highlighted change for 12 months
    } else if (totalMonths < 12) {
      return `${totalMonths + 1} Month${totalMonths === 0 ? '' : 's'}`;
    } else {
      return `${Math.floor(totalMonths / 12)} Year${Math.floor(totalMonths / 12) === 1 ? '' : 's'}`;
    }
  };
  
  const FormattedCalculateSessionDuration = (session) => {
    const duration = calculateSessionDuration(session);
    
    // Map the output to desired format
    if (duration === "12 Months") {
      return "One   Year";
    } else if (duration === "6 Months") {
      return "Six Months";
    } else {
      return duration; // Return other durations as they are
    }
  };

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={{ flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
          {/* Background image */}
          <Image
            src={certificateTemplate}
            style={{
              width: 560,
              height: 800,
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
          
          {/* User photo */}
          {photo && (
            <Image
              src={photo}
              style={{
                width: 80,
                height: 90,
                position: 'absolute',
                top: 260,
                left: 415
              }}
            />
          )}

{courseCode === 'DFSE' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the {' '}
      <Text> {FormattedCalculateSessionDuration(session)}</Text>
      {'  '} {coursePart1}
      {' '} {coursePart2} {' '} at National College of Fire & Safety Engineering Nagpur From {' '}
      <Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to {' '}
      <Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the 
      {' '}<Text style={{ textDecoration: 'underline' }}> 1st </Text>{' '} Division, whose photograph is affixed here above.
    </Text>
  </>
)}

{courseCode === 'CFSE' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the
      {' '}<Text> {FormattedCalculateSessionDuration(session)}</Text>
      {' '} {coursePart1}
      {' '} {coursePart2} at National College of Fire & Safety Engineering Nagpur From
      {' '}<Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to 
      {' '}<Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the 
      {' '}<Text style={{ textDecoration: 'underline' }}> 1st </Text>{' '} Division, whose photograph is affixed here above.
    </Text>
  </>
)}


    

          {courseCode === 'DIS' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the 
      <Text>{' '} {FormattedCalculateSessionDuration(session)}</Text>
      {'  '} {coursePart1}
      {' '} {coursePart2} {' '} at National College of Fire & Safety Engineering Nagpur From 
      <Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to {' '}
      <Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the
      <Text style={{ textDecoration: 'underline' }}> 1st</Text> 
      {' '}Division, whose photograph is affixed here above.
    </Text>
  </>
)}

{courseCode === 'ADISE' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the
      {' '}<Text> {FormattedCalculateSessionDuration(session)}</Text>
      {' '} {coursePart1}
      {' '} {coursePart2} at National College of Fire & Safety Engineering Nagpur From
      {' '}<Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to 
      {' '}<Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the 
      {' '}
      <Text style={{ textDecoration: 'underline' }}> 1st</Text>{' '} Division, whose photograph is affixed here above.
    </Text>
  </>
)}

{courseCode === 'ADFISE' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the
      {' '}<Text> {FormattedCalculateSessionDuration(session)}</Text>
      {' '} {coursePart1}
      {' '} {coursePart2} at National College of Fire & Safety Engineering Nagpur From
      {' '}<Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to 
      {' '}<Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the 
      {' '}
      <Text style={{ textDecoration: 'underline' }}> 1st</Text>{' '} Division, whose photograph is affixed here above.
    </Text>
  </>
)}

{courseCode === 'DFISE' && (
  <>
    <Text style={{ position: 'absolute', top: 71, left: 120, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {srNo}
    </Text>
    <Text style={{ position: 'absolute', top: 297, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {enrollmentNumber}
    </Text>
    <Text style={{ position: 'absolute', top: 309, left: 150, fontSize: 9, width: 300, fontFamily: 'Bold' }}>
      {centerCode}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 445, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formatDate(dateOfBirth)}
    </Text>
    <Text style={{ position: 'absolute', top: 589, left: 130, fontSize: 10, width: 300, fontFamily: 'Bold' }}>
      {formattedIssuedDate}
    </Text>
    {/* Underline For Name */}
    <Text style={{ position: 'absolute', top: 370, left: 285, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      ______________________________
    </Text>
    {/* Underline For Father's Name */}
    <Text style={{ position: 'absolute', top: 400, left: 110, fontSize: 16, width: 300, fontFamily: 'Bold' }}>
      __________________________________
    </Text>
    {/* Certificate Content */}
    <Text style={{ position: 'absolute', top: 370, left: 85, fontSize: 16, width: 390, lineHeight: 2, fontFamily: 'Zafchab' }}>
      {' '} {' '} {' '} This is to Certify that Shri {' '}
      <Text>{' '} {name.toUpperCase()} {' '}</Text>
      <Text>{'\n'} S/o {' '} </Text>
      <Text>{' '} {fathersName.toUpperCase()}</Text>
      <Text>{'                               '} Batch No. <Text style={{ textDecoration: 'underline' }}>XVIII</Text></Text>
      {'\n'} has successfully completed the
      {' '}<Text> {FormattedCalculateSessionDuration(session)}</Text>
      {' '} {coursePart1}
      {' '} {coursePart2} at National College of Fire & Safety Engineering Nagpur From
      {' '}<Text style={{ textDecoration: 'underline' }}> {startSession}</Text>
      {' '} to 
      {' '}<Text style={{ textDecoration: 'underline' }}> {endSession}</Text>
      {' '} and was placed in the 
      {' '}
      <Text style={{ textDecoration: 'underline' }}> 1st</Text>{' '} Division, whose photograph is affixed here above.
    </Text>
  </>
)}




        </View>
      </Page>
    </Document>
  );

  return (
    <Box p={3}>
      <Typography variant="h4" align="center" style={{ fontSize: 36 }}>
        Certificate
      </Typography>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Enrollment Number"
              variant="outlined"
              size="small"
              value={enrollmentNumber}
              onChange={(e) => setEnrollmentNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Session"
              variant="outlined"
              size="small"
              value={session}
              onChange={(e) => setSession(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Course Code"
              variant="outlined"
              size="small"
              value={courseCode}
              onChange={(e) => setCourseCode(e.target.value)}
              disabled
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="center" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            aria-label="Search Certificate"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Search'}
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleGenerateCertificate}
            disabled={!searchCompleted || !photo}
          >
            Generate Certificate
          </Button>
          <Button
            variant="contained"
            color="info"
            component="label"
            aria-label="Upload Photo"
            disabled={!searchCompleted}
          >
            Upload Photo
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </Button>
          {certificateGenerated && showDownloadButton && (
            <PDFDownloadLink
              document={<MyDocument />}
              fileName="certificate.pdf"
              style={styles.downloadButton}
            >
              {({ loading }) => (loading ? 'Preparing document...' : 'Download Certificate')}
            </PDFDownloadLink>
          )}
        </Box>
        {photoError && (
          <Typography color="error" align="center" mt={2}>
            {photoError}
          </Typography>
        )}
        {error && (
          <Typography color="error" align="center" mt={2}>
            {error}
          </Typography>
        )}
        {searchResults.length > 0 && (
          <Box mt={4} p={2} border={1} borderColor="grey.300" borderRadius={2} bgcolor="background.paper">
            <Typography variant="h6" mb={2} color="textPrimary">
              Search Results:
            </Typography>
            <List>
              {searchResults.map((result, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={`Name: ${result.name}`}
                    secondary={
                      <>
                        <div>Father's Name: {result.fathersName}</div>
                        <div>Enrollment Number: {result.enrollmentNumber}</div>
                        <div>Session: {result.session}</div>
                        <div>Course Code: {result.courseCode}</div>
                        <div>Date of Birth: {formatDate(result.dateOfBirth)}</div>
                      </>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {certificateGenerated && (
          <Box mt={4} className={styles.certificateContainer}>
            <Typography variant="h6" mb={2} color="textPrimary">
              Generated Certificate:
            </Typography>
            <PDFViewer width="100%" height="600">
              <MyDocument />
            </PDFViewer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Certificate;
