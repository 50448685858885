import React, { useState, useCallback } from 'react';
import { Drawer, List, ListItem, ListItemText, Divider, Box, Typography } from '@mui/material';
import { Home, School, Assignment, ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';
import Marksheet from './Marksheet';
import Certificate from './Certificate';

const drawerWidth = 240;

const LeftPanel = ({ onLogout }) => {
  const [currentTab, setCurrentTab] = useState('studentRegistration');
  const [resetRegistrationForm, setResetRegistrationForm] = useState(false);
  const navigate = useNavigate();

  const handleTabChange = useCallback((tab) => {
    setCurrentTab(tab);
    if (tab === 'studentRegistration') {
      setResetRegistrationForm(true);
    } else {
      setResetRegistrationForm(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear token on logout
    onLogout(); // Update authentication state
    navigate('/login'); // Redirect to login page
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'studentRegistration':
        return <RegistrationForm reset={resetRegistrationForm} />;
      case 'marksheet':
        return <Marksheet />;
      case 'certificate':
        return <Certificate />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex">
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#303f9f',
            color: '#fff',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box p={2} textAlign="center">
          <Typography variant="h5" color="inherit"></Typography>
        </Box>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }} />
        <List>
          <ListItem button onClick={() => handleTabChange('studentRegistration')}>
            <Home sx={{ mr: 2 }} />
            <ListItemText primary="Student Registration" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleTabChange('marksheet')}>
            <School sx={{ mr: 2 }} />
            <ListItemText primary="Marksheet" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => handleTabChange('certificate')}>
            <Assignment sx={{ mr: 2 }} />
            <ListItemText primary="Certificate" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ExitToApp sx={{ mr: 2 }} />
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: '#eaeaea',
          height: '100vh',
          overflow: 'auto',
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default LeftPanel;
