import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 600,
  width: '100%',
}));

const ForgotPassword = ({ onBack }) => {
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  
  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch('https://backend.navituschemicals.in/resetPassword.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          username, 
          password: newPassword // only sending necessary data
        }),
      });

      const data = await response.json();
    
      if (data.success) {
        setSuccessMessage('Password reset successful!');
        setErrorMessage('');

        // Clear the form fields after successful password reset
        setUsername('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setErrorMessage(data.message || 'Password reset failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
};


  return (
    
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container component="main" maxWidth="sm">
        <StyledPaper elevation={6}>
          <Typography variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          {errorMessage && (
            <Typography color="error" align="center" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography color="success" align="center" gutterBottom>
              {successMessage}
            </Typography>
          )}
          <form onSubmit={handleResetPassword}>
            <Box display="flex" flexDirection="column" gap={2} width="100%">
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="New Password"
                variant="outlined"
                type="password"
                fullWidth
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                label="Retype New Password"
                variant="outlined"
                type="password"
                fullWidth
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Reset Password
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={onBack}
              >
                Back to Login
              </Button>
            </Box>
          </form>
        </StyledPaper>
      </Container>
    </Box>
  );
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  // Load saved username and password from localStorage on component mount
  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');
    
    if (savedUsername) setUsername(savedUsername);
    if (savedPassword) setPassword(savedPassword);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const response = await fetch('https://backend.navituschemicals.in/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    
    if (data.success) {
      console.log('Login successful');
      setErrorMessage('');
      setSuccessMessage('');
      
      if (data.token) {
        localStorage.setItem('authToken', data.token);
      }
      // Save credentials if "Remember Me" is checked
      if (rememberMe) {
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
      }

      onLogin();
      navigate('/');
    } else {
      console.log('Invalid credentials');
      setErrorMessage('Invalid credentials');
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    const response = await fetch('https://backend.navituschemicals.in/signup.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username: newUsername, password: newPassword }),
    });

    const data = await response.json();
    
    if (data.success) {
      console.log('Sign up successful');
      setErrorMessage('');
      setSuccessMessage('Sign Up successful!');
      setIsSignUp(false);
    } else {
      console.log('Sign up failed');
      setErrorMessage(data.message || 'Sign up failed');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isForgotPassword) {
    return <ForgotPassword onBack={() => setIsForgotPassword(false)} />;
  }

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#e0f7fa',
      }}
    >
      <StyledPaper elevation={6}>
        <Typography variant="h4" align="center" gutterBottom>
          {isSignUp ? 'Sign Up' : 'Login'}
        </Typography>
        {errorMessage && (
          <Typography color="error" align="center" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        {successMessage && !isSignUp && (
          <Typography color="success" align="center" gutterBottom>
            {successMessage}
          </Typography>
        )}
        <form onSubmit={isSignUp ? handleSignUp : handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            {isSignUp ? (
              <>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  required
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <TextField
                  label="Retype Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Link href="#" variant="body2" color="primary" onClick={() => setIsSignUp(false)}>
                  Already a User?
                </Link>
              </>
            ) : (
              <>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                  <Link href="#" variant="body2" color="primary" onClick={() => setIsForgotPassword(true)}>
                    Forgot Password?
                  </Link>
                </Box>
              </>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              {isSignUp ? 'Sign Up' : 'Login'}
            </Button>
            <Link href="#" variant="body2" color="primary" onClick={() => setIsSignUp(true)} style={{ display: 'block', textAlign: 'center', margin: '0 auto' }}>
              New User?
            </Link>
          </Box>
        </form>
      </StyledPaper>
    </Container>
  );
};

export default Login;
