// CustomDatePicker.js
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, FormHelperText, Box } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for DatePicker
const StyledDatePickerWrapper = styled(Box)`
  position: relative;
  z-index: 1000; /* Ensures it is above other elements */
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker__header {
    background-color: #f5f5f5; /* Adjust header background color */
  }

  .react-datepicker__current-month {
    font-size: 1rem; /* Adjust font size */
  }

  .react-datepicker__day {
    border-radius: 0; /* Remove border radius for better styling */
  }

  .react-datepicker__day:hover {
    background-color: #e0e0e0; /* Hover effect */
  }
`;

const CustomDatePicker = ({ selected, onChange, placeholderText, error }) => {
  return (
    <StyledDatePickerWrapper>
      <StyledDatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="MMMM yyyy" // Updated to show month and year
        showMonthYearPicker
        placeholderText={placeholderText}
        customInput={
          <TextField
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error}
            InputProps={{
              style: { cursor: 'pointer' },
            }}
          />
        }
        wrapperClassName="date-picker-wrapper"
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </StyledDatePickerWrapper>
  );
};

export default CustomDatePicker;
