//Fully Working Code with Data Uploaded Successful message with File Name Reset V4

import React, { useState, useRef } from "react";
import { Button, Box, Typography, IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BulkRegistration = ({ onBack }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || selectedFile.type === "application/vnd.ms-excel") {
        setFile(selectedFile);
        setError("");
        setSuccess(""); // Clear success message
      } else {
        setError("Please select a valid Excel file (.xlsx, .xls).");
        setFile(null);
        setSuccess(""); // Clear success message
      }
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setError("Please select a file.");
      setSuccess("");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    const formData = new FormData();
    formData.append("file", file);

    const uploadUrl = process.env.REACT_APP_UPLOAD_URL || 'https://backend.navituschemicals.in/upload.php';

    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const result = await response.text();
      console.log(result); // Optionally log the result for debugging

      // Assuming your server returns a specific message indicating success or failure
      if (result.includes("success")) {
        setSuccess("Data Uploaded Successfully");
        setFile(null); // Clear the file after processing
      } else {
        setError("Data not Uploaded Successfully");
      }
    } catch (error) {
      console.error(error); // Log the error details
      setError(`Error uploading file: ${error.message}`);
    } finally {
      setLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset file input
      }
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" alignItems="center">
        <IconButton edge="start" color="inherit" onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" align="center" sx={{ fontSize: 36, flexGrow: 1 }}>
          Bulk Registration
        </Typography>
      </Box>
      <Box mt={4} textAlign="center">
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
          ref={fileInputRef} // Set the ref for file input
          style={{ marginBottom: 20 }}
        />
        {/* Display the name only if a file is selected and no success or error message */}
        {file && !success && !error && (
          <Typography variant="body1">
            Selected file: {file.name}
          </Typography>
        )}
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="success.main">{success}</Typography>}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={loading || !file} // Disable if loading or no file
        >
          {loading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
      </Box>
    </Box>
  );
};

export default BulkRegistration;
